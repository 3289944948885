/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~lightgallery/css/lightgallery.css';
.bordered-section-primary {
  padding: 5px 5px 5px 5px;
  background-color: #eae6ff;
  border: 2px solid light-red;
  border-radius: 5px;
}


.bordered-section {
  padding: 5px 5px 5px 5px;
  background-color: #e6e8ff;
  border: 2px solid light-red;
  border-radius: 5px;
}

.bordered-section-primary {
  padding: 5px 5px 5px 5px;
  background-color: #eae6ff;
  border: 2px solid light-red;
  border-radius: 5px;
}

.missing-image {
  border: 2px solid red;
}


.margined {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.margined-y {
  margin-top: 10px;
}

.cancelled {
  background-color: #fcc5c6!important;
}

.stockDetail {
  background-color: #cee3cc
}
